<template>
  <v-app>
    <v-app-bar app color="white" elevation="6" dark>
      <v-spacer></v-spacer>
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://ldwid-1258491808.file.myqcloud.com/web/LOGO/LDW_2020_Web.png"
          transition="scale-transition"
          width="200"
        />
      </div>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main style="overflow: hidden">
      <v-row>
        <v-col cols="12" class="d-flex align-center justify-center mt-10">
          <h1 style="color: #0066ff">example - ldwid</h1>
        </v-col>
        <v-col cols="12" class="d-flex align-center justify-center mt-1">
          <p style="color: #0066ff">点击下列按钮以跳转至ldwid</p>
        </v-col>
      </v-row>
      <v-row class="mt-10">
        <v-col
          v-for="item in btns"
          :key="item.name"
          cols="12"
          class="d-flex align-center justify-center"
        >
          <v-btn
            :href="item.target"
            target="_blank"
            color="#0066ff"
            class="white--text d-flex justify-start"
            width="350"
            max-width="95vw"
            rounded
            elevation="6"
          >
            <v-icon color="white" class="mr-2">{{ item.icon }}</v-icon>
            {{ item.name }}
          </v-btn>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    btns: [
      {
        name: "部署方式：Github",
        target: "https://likedreamwalker.github.io/ldwid-without-serverless/",
        icon: "mdi-thumb-down",
      },
      {
        name: "部署方式：腾讯云（静态页面）",
        target:
          "	https://ldwid-without-serverless-1258491808.cos-website.ap-beijing.myqcloud.com",
        icon: "mdi-thumb-up-outline",
      },
      {
        name: "部署方式：Github + 腾讯云（COS与CDN）",
        target: "https://likedreamwalker.github.io/ldwid-with-serverless/",
        icon: "mdi-thumb-down-outline",
      },
      {
        name: "腾讯云（静态页面） + 腾讯云（COS与CDN）",
        target:
          "	https://ldwid-with-serverless-1258491808.cos-website.ap-beijing.myqcloud.com",
        icon: "mdi-thumb-up",
      },
      {
        name: "Github + Vercel + 腾讯云（COS与CDN）",
        target: "https://example-ldwid-develop.vercel.app/#/",
        icon: "mdi-thumb-up",
      },
    ],
  }),
};
</script>

